import axios, { AxiosRequestConfig } from "axios";
import { ENV } from "./env";

const api = axios.create({
  baseURL: ENV.BACKEND_API,
  headers: {
    registerapp: `crafthunt`,
  },
});

/** Fetching data from Backend API */
export const fetcher = <T>(
  url: string,
  params?: Record<string, unknown>,
  headers?: Record<string, string>,
  skipRetry?: boolean
) =>
  api
    .get<T>(url, {
      params,
      headers,
    })
    .then((res) => {
      if (!skipRetry && (res.status === 401 || res.status == 403)) {
        // We try without token
        setAuthTokenForApiInstance(null);
        return fetcher(url, params, headers, true);
      }
      return res.data;
    });

/** Posting data to Backend API */
export const post = <T>(
  url: string,
  data?: Record<string, unknown>,
  config?: AxiosRequestConfig
) => api.post<T>(url, data, config).then((res) => res.data);
export const put = <T>(
  url: string,
  data: Record<string, unknown>,
  config?: AxiosRequestConfig
) => api.put<T>(url, data, config).then((res) => res.data);
export const setAuthTokenForApiInstance = (accessToken?: string) => {
  if (accessToken) {
    api.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  } else {
    api.defaults.headers.common["Authorization"] = "";
  }
};

/** Fetching data from NextJS Functions */
export const functionsFetcher = <T>(
  url: string,
  params?: Record<string, unknown>,
  headers?: Record<string, string>,
  skipRetry?: boolean
) =>
  axios
    .get<T>(url, {
      params,
      headers,
    })
    .then((res) => {
      if (!skipRetry && (res.status === 401 || res.status == 403)) {
        // We try without token
        setAuthTokenForApiInstance(null);
        return functionsFetcher(url, params, headers, true);
      }
      return res.data;
    });

/** Posting data to NextJS Functions */
export const functionsPost = <T>(
  url: string,
  data?: Record<string, unknown>,
  config?: AxiosRequestConfig
) => axios.post<T>(url, data, config).then((res) => res.data);

/** Put data to NextJS Functions */
export const functionsPut = <T>(
  url: string,
  data?: Record<string, unknown>,
  config?: AxiosRequestConfig
) => axios.put<T>(url, data, config).then((res) => res.data);

