import { useMount } from "ahooks";
import axios from "axios";
import {
  getStorage,
  removeStorage,
  setStorage,
  StorageTypeDef,
} from "context/context.helper";
import { differenceInDays } from "date-fns";
import { setAuthTokenForApiInstance } from "constants/api";
import { useUpdateStorage } from "hooks/useUpdateStorage";
import Router, { useRouter } from "next/router";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { resetAnalytics, triggerIdentify } from "utils/analytics";
import { cookieEnabled, getCookie } from "utils/helpers";
import { functionsGetUser, functionsLogout } from "../api/auth.serverless.api";
import { isAuthRoute, parseJwt } from "../helpers/auth.helper";
import {
  AuthStatusEnum,
  AuthUserDef,
  TokenPayloadDef,
} from "../types/auth.types";


type AuthContextDef = {
  isLoggedIn?: boolean;
  loading?: boolean;
  accessToken?: string;
  user?: AuthUserDef;
  parsedToken?: TokenPayloadDef;
  logoutAction: () => void;
  loginAction: (user: AuthUserDef, accessToken?: string) => void;
};

export enum RoutesEnum {
  FRONT_PAGE = "/",
  JOB_QUESTIONS = "/job-questions",
  CRAFTSMAN_SIGNUP = "/craftsman-signup",
  SIGNUP_CATEGORY = "/signup",
  PERSONAL_LOGIN_SUCCESS = "/personal-login-success",
  BAUGPT = "/baugpt",
}


const STATUS_KEY = "auth_status";
const ACCESS_TOKEN_KEY = "accessToken";
const USER_KEY = "user";
const USER_DATE_KEY = "user-date";
const STORAGE_TYPE: StorageTypeDef = "sessionStorage";

const AuthContext = createContext<AuthContextDef | null>(null);

type AuthProviderProps = {
  children: ReactNode;
  initialAccessToken?: string;
};

export function AuthProvider({
  children,
  initialAccessToken,
}: AuthProviderProps) {
  const [status, setStatus] = useState<AuthStatusEnum>(
    getStorage<AuthStatusEnum>(STATUS_KEY, STORAGE_TYPE) ||
      (initialAccessToken && AuthStatusEnum.LOADING) ||
      AuthStatusEnum.NO_USER
  );
  const [accessToken, setAccessToken] = useState<string>(
    initialAccessToken || getStorage<string>(ACCESS_TOKEN_KEY, STORAGE_TYPE)
  );
  const [user, setUser] = useState<AuthUserDef>(
    getStorage<AuthUserDef>(USER_KEY, STORAGE_TYPE)
  );
  const [parsedToken, setParsedToken] = useState<TokenPayloadDef>(
    parseJwt(
      initialAccessToken || getStorage<string>(ACCESS_TOKEN_KEY, STORAGE_TYPE)
    )
  );
  const router = useRouter();
  // updates and deletes the states in storage
  useUpdateStorage(STATUS_KEY, status, STORAGE_TYPE);
  useUpdateStorage(ACCESS_TOKEN_KEY, accessToken, STORAGE_TYPE);
  useUpdateStorage(USER_KEY, user, STORAGE_TYPE);

  // LOGIN ACTION
  const loginAction = useCallback((user: AuthUserDef, accessToken?: string) => {
    triggerIdentify(user.id, {
      email: user.email,
      phone: user.phone,
    });
    setStatus(AuthStatusEnum.AUTHENTICATED);
    setUser(user);
    if (accessToken) {
      setAccessToken(accessToken);
    }
  }, []);

  const resetEverything = useCallback(() => {
    resetAnalytics();
    setStatus(AuthStatusEnum.NO_USER);
    setAccessToken(undefined);
    setUser(undefined);
    removeStorage(USER_DATE_KEY, STORAGE_TYPE);

    // Remove data from forms in local and session storage
    // removeStorage(, "localStorage");
    // removeStorage(, "localStorage");
    // removeStorage(, "localStorage");
    // removeStorage(, "sessionStorage");
    // for (const key in window.localStorage) {
    //   if (
    //     key.includes() ||
    //     key.includes()
    //   ) {
    //     removeStorage(key, "localStorage");
    //   }
    // }
    if (isAuthRoute(router.pathname)) {
      Router.replace(RoutesEnum.FRONT_PAGE);
    }
  }, [router.pathname]);

  // LOGOUT ACTION
  const logoutAction = useCallback(async () => {
    try {
      await functionsLogout();
    } finally {
      resetEverything();
    }
  }, [resetEverything]);

  // CONTEXT
  const contextValue = useMemo(
    () => ({
      isLoggedIn: status === AuthStatusEnum.AUTHENTICATED,
      loading: status === AuthStatusEnum.LOADING,
      accessToken,
      user,
      parsedToken,
      logoutAction,
      loginAction,
    }),
    [status, accessToken, user, parsedToken, logoutAction, loginAction]
  );

  // HOOKS
  useMount(() => {
    const getUser = async () => {
      try {
        const response = await functionsGetUser();
        setStorage(USER_DATE_KEY, new Date().toISOString(), STORAGE_TYPE);
        loginAction(response.workerProfile, response.accessToken);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response.status === 401) {
          resetEverything();
        }
      }
    };

    const lastUserDate = getStorage<number>(USER_DATE_KEY, STORAGE_TYPE);
    /**
     * Get the user from backend
     * if user is stored but we haven't update it for more than 1 day
     * if user is not already stored locally AND
     * if either cookies are not supported or
     * we have a cookie set from server that indicates
     * that we need to get the user
     */
    if (
      (user &&
        (!lastUserDate ||
          differenceInDays(new Date(), new Date(lastUserDate)) >= 1)) ||
      (!user &&
        (!cookieEnabled() || getCookie("crafthunt-auth-checker") === "true"))
    ) {
      getUser();
    }
  });

  useEffect(() => {
    if (accessToken) {
      setParsedToken(parseJwt(accessToken));
    } else {
      setParsedToken(undefined);
    }
  }, [accessToken]);

  useEffect(() => {
    if (accessToken && status === AuthStatusEnum.AUTHENTICATED) {
      setAuthTokenForApiInstance(accessToken);
    } else {
      setAuthTokenForApiInstance();
    }
  }, [accessToken, status]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext) as AuthContextDef;
}
