import { functionsFetcher, functionsPost, functionsPut } from "constants/api";
import {
  AuthLoginResponse,
  AuthUserResponse,
  AuthLoginRequest,
  AuthSignUpRequest,
  AuthUserDef,
  UserEditRequest,
} from "../types/auth.types";

export const functionsLogin = (data: AuthLoginRequest) => {
  return functionsPost<AuthLoginResponse>("/api/login", data);
};

export const functionsSignUp = (data: AuthSignUpRequest) => {
  return functionsPost<AuthUserResponse>("/api/sign-up", data);
};

export const functionsLogout = () => {
  return functionsPost("/api/logout");
};

export const functionsGetUser = () => {
  return functionsFetcher<AuthUserResponse>("/api/user");
};

export const functionsEditUser = (data: UserEditRequest) => {
  return functionsPut<AuthUserDef>("/api/user", data);
};
