import "components/RoutesLoading/RoutesLoading.css";
import "../styles/tailwind.scss";
import React, { useEffect } from "react";
import { useRouter } from "next/router";
import TagManager from "react-gtm-module";
import type { AnalyticsSnippet } from "@segment/analytics-next";
import { ENV, isProd } from "constants/env";
import RoutesLoading from "components/RoutesLoading/RoutesLoading";
import { TranslationProvider } from "context/TranslationContext";
import Bugsnag from "utils/bugsnag";
import posthog from "posthog-js";
import { Toaster } from "react-hot-toast";
import { AppProps } from "next/app";
import { useMount } from "react-use";
import { AuthProvider } from "features/auth/context/AuthContext";


declare global {
  interface Window {
    analytics: AnalyticsSnippet;
  }
}

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  useMount(() => {
    if (isProd()) {
      TagManager.initialize({ gtmId: ENV.GOOGLE_TAG_MANAGER_KEY });
      posthog.init(ENV.POSTHOG_API_KEY, {
        api_host: "https://eu.posthog.com",
      });
    }
  });

  useEffect(() => {
    const handleRouteChange = (url) => {
      window.analytics?.page(url);
    };

    router.events.on("routeChangeStart", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  });

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => {
      posthog.capture("$pageview")
    };
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary>
      <TranslationProvider>
      <AuthProvider initialAccessToken={(pageProps as any).accessToken}>
            <RoutesLoading />
            <Toaster />
            <Component {...pageProps} />
            <div id="modals" />
          </AuthProvider>
      </TranslationProvider>
    </ErrorBoundary>
  );
}

export default MyApp;
