import packageJson from "../package.json";

export const ENV = {
  VERSION: packageJson.version || "",
  BACKEND_API: process.env.NEXT_PUBLIC_BACKEND_API ?? "",
  BACKEND_API_KEY: process.env.BACKEND_API_KEY ?? "",
  ANALYTICS_WRITE_KEY: process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY ?? "",
  STORYBLOK_ACCESS_TOKEN: process.env.NEXT_PUBLIC_STORYBLOK_ACCESS_TOKEN ?? "",
  ENVIRONMENT: process.env.NODE_ENV ?? "development",
  GOOGLE_MAPS_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ?? "",
  GOOGLE_TAG_MANAGER_KEY: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_KEY ?? "",
  CRISP_KEY: process.env.NEXT_PUBLIC_CRISP_KEY ?? "",
  BUGSNAG_API_KEY: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY ?? "",
  IS_STAGING: process.env.NEXT_PUBLIC_IS_STAGING === "1",
  MAINTENANCE: process.env.NEXT_PUBLIC_MAINTENANCE ?? "",
  POSTHOG_API_KEY: process.env.NEXT_PUBLIC_POSTHOG_API_KEY ?? "",
  BAUGPT_KEY: process.env.NEXT_PUBLIC_BAUGPT_KEY ?? "",
};

export const isDev = () => {
  return ENV.ENVIRONMENT === "development";
};
export const isProd = () => {
  return ENV.ENVIRONMENT === "production" && !ENV.IS_STAGING;
};
export const isStaging = () => {
  return ENV.ENVIRONMENT === "production" && ENV.IS_STAGING;
};
