import { convertLocaleForAnalytics } from "./language.helper";
import { isProd } from "constants/env";
import { EFromJourney } from "features/auth/types/auth.types";
import { cookieEnabled, getCookie } from "./helpers";



const UTM_PARAMS = [
  "utm_campaign",
  "utm_medium",
  "utm_source",
  "utm_content",
  "utm_term",
  "fbclid",
];

export const triggerIdentify = async (
  userId: string,
  data: {
    email?: string;
    phone?: string;
    name?: string;
    fromJourney?: EFromJourney;
  }
) => {
  if (!isProd()) {
    return;
  }
  const utmParams = getAllUTMs();
  const hubspot_hutk = getHubspotCookie();

  const eventData = {
    ...(data.email && {
      email: data.email,
    }),
    ...(data.phone && {
      phone: data.phone,
    }),
    ...(data.name && {
      name: data.name,
    }),
    ...(data.fromJourney && {
      fromJourney: data.fromJourney,
    }),
    hubspot_hutk,
    ...utmParams,
  };

  window.analytics?.identify(userId, eventData);
};

export const triggerEventSubmitCraftsmanForm = (
  url: string,
  locale: string,
  data: any,
  geoData: { country: string; city: string; isEu: boolean }
) => {
  const domain = data.contact.email.split("@")[1] || "";
  const { country, city, isEu } = geoData;

  // Track for Submit in Segment
  window.analytics?.track("craftsman_signup_completed", {
    url,
    domain,
    language: convertLocaleForAnalytics(locale),
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.contact.email,
    phone: data.contact.phone,
    trades: data.trades.data.map((trade) => trade.name),
    role: data.role.name.name,
    address: {
      ...data.address?.address,
      lat: data.address?.lat,
      lng: data.address?.lng,
      radius: data.address?.radius,
    },
    flexibleLocation: data.flexibleLocation,
    country,
    city,
    isEu,
    type: "craftsman",
  });
};

export const triggerEventSignUpCity = (values: any) => {
  window.analytics?.track("six_account_signup_city", {
    city: (!values.flexibleLocation && values.address.address?.city) || "",
    flexibleLocation: !!values.flexibleLocation,
  });
};

export const triggerIdentifyByEmail = (
  email: string,
  locale: string,
  geoData: { country: string; city: string; isEu: boolean }
) => {
  const { country, city, isEu } = geoData;
  window.analytics?.identify(email, {
    language: convertLocaleForAnalytics(locale),
    type: "craftsman",
    email,
    country,
    city,
    isEu,
  });
};

/**
 * This is triggered when the users enters their email or phone before entering the sign up flow
 */
export const triggerFormSubmitNewsletter = (
  email: string,
  phone: string,
  url: string,
  locale: string,
  geoData: { country: string; city: string; isEu: boolean }
) => {
  const { country, city, isEu } = geoData;

  // Track for Submit in Segment
  window.analytics?.track("form_submit", {
    message: email,
    phone: phone,
    type: "craftsman",
    inputType: email ? "email" : "phone",
    url,
    language: convertLocaleForAnalytics(locale),
    country,
    city,
    isEu,
  });

  // Custom Google Tag Manager Conversion
  // window.dataLayer?.push({
  //   event: "signup",
  //   eventProps: {
  //     action: "signup",
  //     category: "newsletter",
  //     label: "email",
  //     value: email,
  //   },
  // });
};

export const resetAnalytics = () => {
  if (isProd()) {
    window.analytics?.reset();
  }
};

const getAllUTMs = () => {
  let searchQuery = decodeURIComponent(window.location.search.substring(1));
  if (!searchQuery) {
    // in case the params are not found, then try manually
    const splitUrl = window.location.href.split("?");
    if (splitUrl.length > 1) {
      searchQuery = decodeURIComponent(splitUrl[1]);
    }
  }
  const urlParams = searchQuery.split("&").map((x) => {
    const [key, value] = x.split("=");
    return { key, value };
  });
  const utmParams: Record<string, string> = {};
  for (const urlParam of urlParams) {
    if (UTM_PARAMS.includes(urlParam.key)) {
      utmParams[urlParam.key] = urlParam.value;
    }
  }
  return utmParams;
};

const getHubspotCookie = () => {
  if (cookieEnabled()) {
    return getCookie("hubspotutk");
  }
  return null;
};
