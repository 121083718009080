import { isDev } from "constants/env";
import { TranslationDef } from "types/translation.type";
import _kebabCase from "lodash/kebabCase";
import { nanoid } from "nanoid";
import { GetServerSidePropsContext } from "next";

export const getLocalizedString = (
  translations?: TranslationDef[],
  translationKey?: string
) => {
  if (!translations || translations.length <= 0 || !translationKey) return "";

  const res = translations.find((item) => {
    return item.name === translationKey;
  });
  if (res) {
    return res.dimension_value ?? res.value;
  }
  return isDev() ? `${translationKey}_MISSING!⚠️` : translationKey;
};

// truncate strings
export const truncate = (str: string, n: number) => {
  return str.length > n ? str.substring(0, n) + "..." : str;
};

export const isClientSide = () => {
  return typeof window !== "undefined";
};

export const getSlug = (title: string) => {
  return `${_kebabCase(title).replace("ț", "t").replace("ș", "s")}-${nanoid(
    8
  )}`;
};

export const removeEmpty = <T>(obj: T) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([, v]) => v != null)
  ) as T;
};

export const cookieEnabled = () => {
  // source: https://github.com/Modernizr/Modernizr/blob/master/feature-detects/storage/cookies.js
  // navigator.cookieEnabled cannot detect custom or nuanced cookie blocking
  // configurations. For example, when blocking cookies via the Advanced
  // Privacy Settings in IE9, it always returns true. And there have been
  // issues in the past with site-specific exceptions.
  // Don't rely on it.

  // try..catch because some in situations `document.cookie` is exposed but throws a
  // SecurityError if you try to access it; e.g. documents created from data URIs
  // or in sandboxed iframes (depending on flags/context)
  try {
    // Create cookie
    document.cookie = "cookietest=1";
    const ret = document.cookie.indexOf("cookietest=") !== -1;
    // Delete cookie
    document.cookie = "cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT";
    return ret;
  } catch (e) {
    return false;
  }
};

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

export const hasInApp = (
  query: GetServerSidePropsContext["query"],
  path: string
) => {
  const inApp =
    typeof query?.inApp === "string" && query.inApp.toLowerCase() === "true";
  if (inApp) {
    return inApp;
  }
  return path?.includes("inApp=true") || false;
};

const regexLangLong = /^(\/..\/)/ // contains: /xx/
const regexLangShort = /^(..\/)/ // contains: xx/
export const getSlugData = (url: string) => {
  let lang: string = undefined;
  let slug = "";

  if (regexLangLong.test(url)) {
    lang = url.slice(1, 3);
    slug = url.slice(4)
  }
  else if (regexLangShort.test(url)) {
    lang = url.slice(0, 2);
    slug = url.slice(3)
  } else {
    slug = url;
  }

  return {
    lang,
    slug
  }
}


export const assignMetas = (pageName, path = window.location.href, injectDynamicContent = false, pageData = null) => {
  if (!injectDynamicContent) { // static pages
    let exist = pageData.filter((x) => x.pageName === pageName);
    if (exist.length > 0) {
      document.title = exist[0].title;

      // remove stale metas
      Array.from(document.querySelectorAll('[data-vue-meta-controlled]')).map(el => el.parentNode.removeChild(el));

      exist[0].tags.map(tagDef => {
        let tag = document.createElement('meta')
        let urlHelperVal = false // will help us search for 'og:url'
        Object.keys(tagDef).forEach(key => {
          tag.setAttribute(key, urlHelperVal ? path : tagDef[key]);
          urlHelperVal = tagDef[key] === "og:url"
        })
        tag.setAttribute('data-vue-meta-controlled', '')
        return tag;
      }).forEach(tag => document.head.appendChild(tag));
    }
  } else { // dynamic pages (e.g blog post page)
    document.title = pageData.title;

    // remove stale metas
    Array.from(document.querySelectorAll('[data-vue-meta-controlled]')).map(el => el.parentNode.removeChild(el));

    pageData.tags.map(tagDef => {
      let tag = document.createElement('meta')
      let urlHelperVal = false // will help us search for 'og:url'
      Object.keys(tagDef).forEach(key => {
        const value = tagDef[key];
        tag.setAttribute(key, urlHelperVal ? path : value);
        urlHelperVal = value === "og:url"
        // Check for open graph or twitter, to add a `property` field as well
        if (key === "name" && /^(og|twitter)/.test(value)) {
          tag.setAttribute("property", value)
        }
      })
      tag.setAttribute('data-vue-meta-controlled', '')
      return tag;
    }).forEach(tag => document.head.appendChild(tag));
  }
}