import {
  StorageTypeDef,
  removeStorage,
  setStorage,
} from "context/context.helper";
import { useEffect } from "react";

export function useUpdateStorage(
  key: string,
  data: unknown,
  storageType: StorageTypeDef = "localStorage"
) {
  useEffect(() => {
    if (data) {
      setStorage(key, data, storageType);
    } else {
      removeStorage(key, storageType);
    }
  }, [data, key, storageType]);
}
