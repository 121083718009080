import { isClientSide } from "utils/helpers";

export type StorageTypeDef = "localStorage" | "sessionStorage";

const parseJsonFromStorage = <T>(key: string, storageType: StorageTypeDef) => {
  // window and sessionStorage/localStorage might not been accessible
  // if browser is incognito or private mode
  const value =
    storageType === "sessionStorage"
      ? window?.sessionStorage?.getItem(key)
      : window?.localStorage?.getItem(key);
  return value ? (JSON.parse(value) as T) : undefined;
};

export const getStorage = <T>(key: string, storageType?: StorageTypeDef) => {
  if (!isClientSide()) {
    return null;
  }
  return parseJsonFromStorage<T>(key, storageType) || null;
};

export const setStorage = (
  key: string,
  data: unknown,
  storageType?: StorageTypeDef
) => {
  const stringified = JSON.stringify(data || "");
  if (storageType === "sessionStorage") {
    return window?.sessionStorage?.setItem(key, stringified);
  }
  return window?.localStorage?.setItem(key, stringified);
};

export const removeStorage = (key: string, storageType?: StorageTypeDef) => {
  if (storageType === "sessionStorage") {
    return window?.sessionStorage?.removeItem(key);
  }
  return window?.localStorage?.removeItem(key);
};
