import { AddressDef } from "types/address.types";
import {
  CrafthuntExternalLinkDef,
  CrafthuntMediaDef,
  CrafthuntTagDef,
} from "types/crafthunt-profile.types";

export enum EFromJourney {
  DEFAULT = "default",
  BAUGPT = "baugpt",
}

export type CreateCoordinatesDef = {
  lat: number;
  lng: number;
};
export type GeoSearchAddressDef = {
  country: string;
  country_original?: string;
  countryCode?: string;
  city: string;
  city_original?: string;
  radius?: number;
  lat: number;
  lng: number;
};

export type CreateAddressDef = {
  city: string;
  country: string;
  countryCode?: string;
};

export type AuthUserDef = {
  id: string;
  firstName: string;
  lastName: string;
  email: string | null;
  phone: string | null;
  slug: string;
  anonymousSlug: string;
  isAnonymous: boolean;
  description: string | null;
  geoSearchAddress: GeoSearchAddressDef | null;
  address: AddressDef | null;
  picture: string | null;
  birthday: string | null;
  jobTitle: CrafthuntTagDef;
  profileTags: CrafthuntTagDef[];
  profileTrades: CrafthuntTagDef[];
  medias: CrafthuntMediaDef[];
};

export enum AuthStatusEnum {
  LOADING,
  NO_USER,
  AUTHENTICATED,
}

// Requests
export type AuthLoginRequest = {
  jobAdId?: string;
  fromJourney?: EFromJourney;
} & (
  | {
      email: string;
      phone?: string | null;
    }
  | {
      email?: string | null;
      phone: string;
    }
);
export type AuthSignUpRequest = {
  name: string;
  lang?: string;
  address?: CreateAddressDef;
  coordinate?: CreateCoordinatesDef;
  fromJourney?: EFromJourney;
} & AuthLoginRequest;

export type UserEditRequest = {
  firstName?: string;
  lastName?: string;
  description?: string;
  picture?: string;
  profileTags?: CrafthuntTagDef[];
  profileTrades?: CrafthuntTagDef[];
  isAnonymous?: boolean;
  jobTitleId?: string;
  geoSearchAddress?: GeoSearchAddressDef;
  address?: CreateAddressDef;
  coordinate?: CreateCoordinatesDef;
  lang?: string;
  medias?: CrafthuntMediaDef[];
  externalLinks?: CrafthuntExternalLinkDef[];
};

// Responses
export type AuthUserResponse = {
  workerProfile: AuthUserDef;
  accessToken: string;
};
export type AuthLoginResponse = {
  message?: string;
} & Partial<AuthUserResponse>;

export type TokenPayloadDef = {
  id: string;
  firstName: string;
  lastName: string;
  email: string | null;
  phone: string | null;
  isCrafthuntCompanyAdmin: boolean;
  iat: number;
  exp: number;
  aud: "https://crafthunt.app";
  iss: "crafthunt";
  jti: string;
};
