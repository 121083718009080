import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { TranslationDef } from "types/translation.type";

type TranslationContextDef = {
  translations: TranslationDef[];
  setTranslations?: (value: TranslationDef[]) => void;
};

const TranslationContext = createContext<TranslationContextDef>({
  translations: [],
});

export function TranslationProvider({ children }) {
  const [translations, setTranslations] = useState<TranslationDef[]>([]);

  // memoize the full context value
  const contextValue = useMemo(
    () => ({
      translations,
      setTranslations,
    }),
    [translations, setTranslations]
  );

  return (
    <TranslationContext.Provider value={contextValue}>
      {children}
    </TranslationContext.Provider>
  );
}

export function useTranslations(newTranslations?: TranslationDef[]) {
  const { translations, setTranslations } = useContext(TranslationContext);

  useEffect(() => {
    if (newTranslations) {
      setTranslations(newTranslations);
    }
  }, [newTranslations, setTranslations]);

  return { translations, setTranslations };
}
