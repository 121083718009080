import { Base64 } from "js-base64";

export type TokenPayloadDef = {
  id: string;
  firstName: string;
  lastName: string;
  email: string | null;
  phone: string | null;
  isCrafthuntCompanyAdmin: boolean;
  iat: number;
  exp: number;
  aud: "https://crafthunt.app";
  iss: "crafthunt";
  jti: string;
};

export enum AuthRoutesEnum {
  MY_JOB_APPLICATIONS = "/my-job-applications",
  APPLY_TO_JOB = "/job-apply/[id]",
}

export const parseJwt = (token?: string) => {
  if (!token) {
    return null;
  }
  const [, base64Url] = token.split(".");
  if (!base64Url) {
    return null;
  }
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  return JSON.parse(Base64.decode(base64)) as TokenPayloadDef;
};

export const isAuthRoute = (path: string) => {
  return Object.values(AuthRoutesEnum).includes(path as AuthRoutesEnum);
};
